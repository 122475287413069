import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';

export const Composting = () => (
    <>
        {/* Food Scraps Drop-off Section */}
        <Container className='text-center py-5 my-5 rounded shadow composting-container'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12}>
                    <p className="composting-text-title">Waste Management</p>
                    <h1 className="composting-heading">Food Scraps Drop-off</h1>
                    <p className="lead composting-text-title">
                        Every Saturday, June 1 - November 23 from 9am - 3pm
                    </p>
                </Col>
            </Row>
        </Container>

        {/* Composting Information Section */}
        <Container className='py-5 my-5 rounded shadow composting-container composting-section'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={6} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <h3 className="mb-4 composting-subheading">What Is It?</h3>
                    <p className="composting-text">
                        Composting is a process of recycling a mixture of organic waste including
                        food scraps and yard clippings and converting them into nutrient-dense soil.
                    </p>
                </Col>
                <Col xs={12} md={6} className="d-flex justify-content-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/composting/composting_1.jpg`}
                        alt='composting'
                        className='img-fluid composting-image' />
                </Col>
            </Row>
        </Container>

        {/* Why Compost? Section */}
        <Container className='py-5 my-5 rounded shadow composting-container composting-section'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <h3 className="mb-4 composting-subheading">Why Compost?</h3>
                    <ul className="composting-list">
                        <li>Reduces Landfill Waste</li>
                        <li>Decreases Greenhouse Gas Emissions</li>
                        <li>Decreases Water &amp; Soil Pollution</li>
                        <li>Prevents Community Exposure to Harmful Chemicals</li>
                    </ul>
                </Col>
                <Col xs={12} md={7} className="d-flex justify-content-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/composting/composting_2.jpg`}
                        alt='composting'
                        className='img-fluid composting-image' />
                </Col>
            </Row>
        </Container>

        {/* What to Drop Off Section */}
        <Container className='py-5 my-5 rounded shadow composting-container composting-section'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <h3 className="mb-4 composting-subheading">What to Drop Off</h3>
                </Col>
                <Col xs={12} md={7} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <ul className="composting-list">
                        <li>Fruit &amp; Vegetable Scraps and Spoils</li>
                        <li>Nutshells &amp; Chopped Seeds</li>
                        <li>Coffee Grounds and Tea Bags</li>
                        <li>Eggshells</li>
                        <li>Baked Goods, Bread, Rice and Pasta</li>
                        <li>Yard Waste (grass, leaves, plant clippings)</li>
                    </ul>
                </Col>
            </Row>
        </Container>

        {/* Drop Off Location Section */}
        <Container className='py-5 my-5 rounded shadow composting-container composting-section'>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={5} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <h3 className="mb-4 composting-subheading">Drop Off Location</h3>
                </Col>
                <Col xs={12} md={7} className="d-flex flex-column justify-content-center text-center text-md-start">
                    <p className="composting-location">
                        St Nicholas Park <br /> St Nicholas Ave &amp; W137th Street, Harlem 10030
                    </p>
                    <p className="composting-location">
                        Food scraps are processed at <br /> St Nicholas Miracle Garden
                    </p>
                </Col>
            </Row>
        </Container>

        {/* 3-Bin Compost System Section */}
        <Container className='py-5 my-5 rounded shadow composting-container composting-section'>
            <h3 className="mb-4 composting-subheading">3-Bin Compost System</h3>
            <Row className='justify-content-center align-items-center'>
                <Col xs={12} md={12} className="d-flex justify-content-center">
                    <img src={`${process.env.PUBLIC_URL}/assets/composting/3-bin-system.jpg`}
                        alt='composting'
                        className='img-fluid composting-image w-100' />
                </Col>
                <Col xs={12} md={12} className="d-flex flex-column justify-content-center text-center text-md-start pt-4">
                    <p className="composting-text">
                        A three-bin compost system is an efficient and organized method for managing composting at home or in community gardens.
                        It consists of three separate bins or compartments, each serving a distinct phase of the composting process.
                        The first bin collects fresh organic waste, such as kitchen scraps and garden clippings, which begins decomposition.
                        The second bin turns and aerates the partially decomposed material from the first bin, facilitating further breakdown
                        and heat generation necessary for effective composting. The third bin contains fully matured compost that has decomposed
                        into rich, dark humus, ready to be used as a nutrient-rich soil amendment. This system ensures continuous compost production,
                        maintains optimal conditions for decomposition, and makes it easier to manage different stages of the composting process.
                    </p>
                </Col>
            </Row>
        </Container>
    </>
);

